.hero-search-container {
    background-color: $bc-color-ltblue;
    .row {
        width: 100%;
    }

    .ms-container-background-image {
        max-height: 400px !important;
        min-height: 400px !important;
        position: relative;
        z-index: 1;
        display: flex;

        &::after {
            content: '';
            position: absolute;
            background-color: transparentize($color: $msv-black, $amount: 0.75);
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: -1;

        }
    }
}

.hero-search-bar {
    height: 20vh;
    min-height: 150px;
    width: 35%;
    margin: 1em auto 0;
    position: relative;

    .ms-search__form {
        form {
            padding: 0;

        }

        input {
            @include font-body-regular-m();
            height: 50px;
            padding: 12px 80px 12px 16px;
            margin-left: 0;
            text-align: center;
            border: none;
        }

        input::placeholder {
            color: $bc-color-mdblue;
        }
    }

    .ms-search__form-submitSearch,
    .ms-search__form-cancelSearch {
        position: absolute;
        right: 0;
        transform: translateX(-100%);
        margin: 0;
        padding: 0;
    }

    .ms-search__form-cancelSearch {
        padding: 0 24px 0 0;
        background-color: transparent;
    }

    .ms-search__autoSuggest {
        &.show {
            .msc-autoSuggest__search-title {
                color: $msv-white;
            }

            .ms-search__form-cancelSearch {
                display: none;
            }
        }

        .msc-autoSuggest__results-container .msc-autoSuggest__results,
        .msc-autoSuggest__productResults-title {
            display: none;
        }
    }
}