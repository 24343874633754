$msv-checkout-billing-address-margin: 4px;
$msv-checkout-input-margin-left: 0;
$msv-checkout-input-dimensions: 16px;
$msv-checkout-shipping-address-label-margin-bottom: 12px;
$msv-checkout-shipping-address-checkbox-margin-left: 8px;
$msv-checkout-address-detail-margin-left: 0;
$msv-checkout-address-detail-padding-bottom: 20px;
$msv-checkout-shipping-address-button-add-padding: 0 13px;
$msv-checkout-shipping-address-button-add-content: "+";
$msv-checkout-shipping-address-detail-padding: 10px 13.5px 10px 0;
$msv-checkout-shipping-address-detail-padding-right: 48px;

//style presets
:root {
    --msv-checkout-shipping-address-font-size: var(--msv-body-font-size-m);
    --msv-checkout-billing-address-font-size: var(--msv-body-font-size-m);
    --msv-checkout-shipping-address-font-color: #{$msv-gray-20};
    --msv-checkout-billing-address-font-color: var(--msv-font-primary-color);
    --msv-checkout-shipping-address-button-add-color: #{$msv-blue};
    --msv-checkout-shipping-address-button-hover-background-color: #{$msv-white-20};

    // heading
    --msv-checkout-billing-address-heading-font-color: #{$msv-gray-20};
    --msv-checkout-billing-address-heading-font-size: var(--msv-body-font-size-m);

    // secondary button
    --msv-checkout-shipping-address-secondary-btn-bg: var(--msv-secondary-button-background-color);
    --msv-checkout-shipping-address-secondary-btn-font-color: var(--msv-font-primary-color);
    --msv-checkout-shipping-address-secondary-btn-border: var(--msv-accent-brand-color);
}

.ms-checkout-billing-address {
    &__heading {
        @include font-heading-h5-l();
        color: var(--msv-checkout-billing-address-heading-font-color);
        margin-bottom: $msv-checkout-billing-address-margin;
    }

    .msc-address-detail {
        @include font-body-regular-m();
        color: var(--msv-checkout-billing-address-font-color);
        margin-left: $msv-checkout-address-detail-margin-left;
        padding-bottom: 25px;

        .msc-address-detail__item-address-detail_Phone {
            margin-top: 5px;
        }
    }

    &__shipping-address-label {
        display: flex;
        margin-bottom: $msv-checkout-shipping-address-label-margin-bottom;
        width: fit-content;
    }

    &__input {
        min-width: $msv-checkout-input-dimensions;
        min-height: $msv-checkout-input-dimensions;
        margin-left: $msv-checkout-input-margin-left;
    }

    &__shipping-address-checkbox-text {
        @include font-body-regular-m();
        margin-left: $msv-checkout-shipping-address-checkbox-margin-left;
        align-self: center;
    }

    .msc-address-form__item-street {
        padding-right: 12px;

        .msc-address-form__input-text {
            padding: 0 0 0 12px;
        }

        .MicrosoftMap {
            width: 100%;

            .as_container_search {
                @media screen and (min-width: $msv-breakpoint-l) {
                    width: 856px;
                }
            }
        }
    }
}

.ms-checkout-shipping-address {
    .msc-address-select__button-add {
        @include secondary-button-light();
        text-transform: uppercase;
    }

    .msc-address-select__button-add-new {
        @include font-body-regular-s();
        line-height: 100%;
        background-color: transparent;
        border: 0;
        display: inline-flex;
        align-items: center;
        cursor: pointer;
        padding: $msv-checkout-shipping-address-detail-padding;

        &::before {
            content: $msv-checkout-shipping-address-button-add-content;
            padding: $msv-checkout-shipping-address-button-add-padding;
            color: var(--msv-checkout-shipping-address-button-add-color);
            font-size: $msv-font-size-ml;
        }

        &:hover {
            background: var(--msv-checkout-shipping-address-button-hover-background-color);
        }

        @media screen and (max-width: $msv-breakpoint-m) {
            margin-bottom: 20px;
        }
    }

    .msc-address-detail {
        @include font-body-regular-m();
        color: var(--msv-checkout-shipping-address-font-color);
        padding-right: $msv-checkout-shipping-address-detail-padding-right;
    }

    .msc-address-form__label,
    .msc-address-form__dropdown {
        color: var(--msv-checkout-address-font-color);
    }

    .msc-address-select__button-ship-multiple-addresses {
        background: transparent;
        border: none;
        color: var(--msv-link-font-color);
        font-family: $bc-primary-font-family;
        font-size: var(--msv-link-font-size-xs);
        font-style: normal;
        font-weight: 400;
        line-height: var(--msv-link-line-height-xs);
        padding: 0;
        text-decoration: underline;
        margin-bottom: 30px;

        @media screen and (max-width: $msv-breakpoint-m) {
            align-self: start;
            margin-bottom: 10px;
        }
    }

    .msc-address-select__button-ship-single-address {
        background: transparent;
        border: none;
        color: var(--msv-link-font-color);
        font-family: $bc-primary-font-family;
        font-size: var(--msv-link-font-size-xs);
        font-style: normal;
        font-weight: 400;
        line-height: var(--msv-link-line-height-xs);
        margin-bottom: 10px;
        padding: 0;
        text-decoration: underline;

        @media screen and (max-width: $msv-breakpoint-m) {
            display: flex;
            margin-bottom: 20px;
        }
    }

    .msc-address-select__ship-single-button-container {
        width: 100%;
        text-align: right;
    }

    .msc-address-select__clear-selection-button {
        background: #FFFFFF;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: $bc-primary-font-family;
        font-size: x-small;
        font-style: normal;
        font-weight: 400;
        height: 30px;
        width: 115px;
        line-height: 20px;
        outline: none;
        padding: 16px 5px;
        border-right: 1px solid var(--msv-secondary-btn-light-pressed-bg-color);
        margin-right: 3px;

        &::before {
            @include msv-icon();
            content: $msv-trash;
            font-size: 18px;
            padding-right: 5px;

            @media screen and (max-width: $msv-breakpoint-m) {
                color: $msv-blue;
            }
        }

        @media screen and (max-width: $msv-breakpoint-m) {
            border-right: none;
            font-size: 12px;
            width: 130px;
        }
    }

    .msc-address-select__address-dropdown {
        background: #FFFFFF;
        border: 1px solid var(--msv-secondary-btn-light-pressed-bg-color);
        border-radius: 4px;
        box-sizing: border-box;
        font-family: $bc-primary-font-family;
        font-size: small;
        font-style: normal;
        font-weight: 400;
        height: 30px;
        line-height: 20px;
        outline: none;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @media screen and (max-width: $msv-breakpoint-m) {
            height: 50px;
            font-size: 14px;
        }
    }

    .msc-address-select__address-dropdown option {
        font-family: $bc-primary-font-family;
        font-size: small;
        font-style: normal;
        font-weight: 400;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .msc-address-select__current-address-dropdown {
        background: #FFFFFF;
        border: 1px solid var(--msv-secondary-btn-light-pressed-bg-color);
        border-radius: 3px;
        box-sizing: border-box;
        align-items: center;
        font-family: $bc-primary-font-family;
        font-size: x-small;
        font-style: normal;
        font-weight: 400;
        height: 30px;
        width: 170px;
        line-height: 30px;
        position: relative;
        padding-right: 20px;

        @media screen and (max-width: $msv-breakpoint-m) {
            width: 285px;
            font-size: small;
            height: 50px;
        }
    }

    .msc-address-select__current-address-dropdown option {
        font-family: $bc-primary-font-family;
        font-size: small;
        font-style: normal;
        font-weight: 400;
    }

    .msc-address-select__current-address-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        max-width: calc(100% - 10px);
    }

    .msc-address-select__address-button-container {
        display: flex;
        justify-content: space-between;

        @media screen and (max-width: $msv-breakpoint-m) {
            display: flex;
            flex-direction: column-reverse;
        }
    }

    .msc-address-select__heading-button-container {
        display: flex;
        margin-bottom: 5px;

        @media screen and (max-width: $msv-breakpoint-m) {
            flex-direction: column-reverse;
        }
    }

    .msc-address-select__dropdown-button-wrapper {
        width: 100%;
        margin-left: 10px;

        @media screen and (max-width: $msv-breakpoint-m) {
            margin-left: 0;
        }
    }

    .msc-address-select__save-continue-button {
        @include primary-button-light();
        margin-left: 10px;

        @media screen and (max-width: $msv-breakpoint-m) {
            width: 100%;

            margin-left: 0;

            margin-top: 10px;
        }
    }

    .msc-address-select__cancel-button {
        @include secondary-button-light();

        @media screen and (max-width: $msv-breakpoint-m) {
            width: 100%;
        }
    }

    .msc-address-select__button-footer-wrapper {
        display: flex;
        margin-top: 40px;

        @media screen and (max-width: $msv-breakpoint-m) {
            display: block;
            margin-top: 10px;
        }
    }

    .msc-address-select__product-price {
        display: flex;
        margin-top: 10px;
        font-family: $bc-primary-font-family;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
    }

    .ms-address-select__table {
        width: 100%;
        border-collapse: collapse;

        th {
            text-align: left;
        }

        tr {
            height: 82px;
        }

        .ms-table__heading-row {
            @include font-body-bold-xs();
            height: 50px;
            border-top: 1px solid var(--msv-secondary-btn-light-pressed-bg-color);
            border-bottom: 1px solid var(--msv-secondary-btn-light-pressed-bg-color);
        }

        .ms-address-select-table-line {
            height: 82px;

            color: var(--msv-body-font-color);
            font-family: $bc-primary-font-family;
            font-size: var(--msv-body-font-size-xs);
            font-style: normal;
            font-weight: 400;
            line-height: var(--msv-body-line-height-s);

            border-bottom: 1px solid var(--msv-secondary-btn-light-pressed-bg-color);

            &__heading {
                height: 43px;

                color: var(--msv-body-font-color);
                font-family: $bc-primary-font-family;
                font-size: var(--msv-body-font-size-xs);
                font-style: normal;
                font-weight: 700;
                line-height: var(--msv-body-line-height-xs);

                th {
                    @include font-content(var(--msv-font-weight-normal),
                        var(--msv-address-select-font-size), $msv-line-height-m);
                    text-align: start;
                }
            }

            @media screen and (max-width: $msv-breakpoint-m) {
                height: 150px;
            }
        }
    }
}

.ms-checkout-section-container.has-initialized.is-updating.col-12.fullwidth {
    padding-left: 0;
    padding-right: 0;
}