@import "../common/00-settings/responsive-breakpoints.scss";

$msv-interactive-feature-collection-flipper-position-mobile: -15px;
$msv-interactive-feature-collection-flipper-position: 20px;
$rtl-msv-interactive-feature-collection-carousel-padding-left: 20px;
$rtl-msv-interactive-feature-collection-carousel-item-tabwidth: 348px;
$rtl-msv-interactive-feature-collection-carousel-item-mobilewidth: 328px;

[dir="rtl"] {
    .ms-interactive-feature-collection {
        .ms-interactive-feature-collection-desktop {
            .ms-interactive-feature-collection-mosaic {
                &__details {
                    .ms-interactive-feature-collection {
                        &__title {
                            text-align: right;
                        }

                        &__links {
                            text-align: right;
                        }
                    }
                }

                &:hover, &:focus, &:focus-within {
                    &:nth-child(even) {
                        transform-origin: top left;
                    }

                    &:nth-child(odd) {
                        transform-origin: top right;
                    }
                }

                &.lastRow {
                    &:hover, &:focus, &:focus-within {
                        &:nth-child(even) {
                            transform-origin: bottom left;
                        }

                        &:nth-child(odd) {
                            transform-origin: bottom right;
                        }
                    }
                }

                &.item-corner-right {
                    &:hover, &:focus, &:focus-within {
                        transform-origin: top left;
                    }

                    &.lastRow {
                        &:hover, &:focus, &:focus-within {
                            transform-origin: bottom left;
                        }
                    }
                }
            }
        }

        .ms-interactive-feature-collection-mobile {
            .msc-ss-carousel {
                position: relative;

                .msc-flipper {
                    &.msc-ss-carousel__flipper {
                        left: auto;

                        @media screen and (max-width: $msv-breakpoint-m) {
                            right: $msv-interactive-feature-collection-flipper-position-mobile;
                        }

                        @media (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l) {
                            right: $msv-interactive-feature-collection-flipper-position-mobile;
                        }
                    }

                    &.msc-ss-carousel__flipper--next {
                        right: auto;

                        @media screen and (max-width: $msv-breakpoint-m) {
                            left: $msv-interactive-feature-collection-flipper-position-mobile;
                        }

                        @media (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l) {
                            left: $msv-interactive-feature-collection-flipper-position-mobile;
                        }
                    }
                }

                .ms-interactive-feature-collection-carousel {
                    @media (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l) {
                        padding-right: 0;
                        padding-left: 0;
                        margin-left: $rtl-msv-interactive-feature-collection-carousel-padding-left;
                        width: $rtl-msv-interactive-feature-collection-carousel-item-tabwidth;
                    }

                    @media (max-width: $msv-breakpoint-m - 1) {
                        padding-right: 0;
                        padding-left: 0;
                        margin-left: $rtl-msv-interactive-feature-collection-carousel-padding-left;
                        width: $rtl-msv-interactive-feature-collection-carousel-item-mobilewidth;
                    }

                    .ms-interactive-feature-collection-carousel__container {
                        text-align: right;

                        .ms-interactive-feature-collection-carousel__heading {
                            .ms-interactive-feature-collection {
                                &__links {
                                    text-align: right;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .ms-interactive-feature-collection[data-m-layout="mosaic"] {
        .ms-interactive-feature-collection-mobile {
            .msc-ss-carousel {
                .ms-interactive-feature-collection-carousel {
                    .ms-interactive-feature-collection-carousel__container {
                        text-align: right;
                    }
                }
            }
        }
    }
}
