$msv-checkout-description-margin-left: 20px;
$msv-checkout-option-margin-bottom: 20px;
$msv-checkout-delivery-options-price-margin-top: 4px;
$msv-checkout-delivery-options-description-margin-left: 0;
$msv-checkout-delivery-options-error-message-padding: 10px 20px 20px 64px;
$msv-checkout-delivery-options-error-title-padding: 20px 20px 0 18px;
$msv-checkout-delivery-options-error-title-icon-margin-right: 22px;
$cart-lines-product-image-link-size: 185px;
$cart-lines-product-image-size: 183px;
$cart-lines-quantity-label-margin: 0 0 5px;
$cart-cartline-product-variant-name-padding-left: 5px;
$msv-cart-item-quantity-controls-padding: 5px 0;
$msv-cart-item-quantity-controls-width: 52px;
$msv-cart-price-editor-container-padding: 6px 8px;
$msv-cart-price-editor-container-padding-top: 40px;
$msv-cart-price-editor-container-actions-padding-left: 10px;
$msv-cart-price-editor-container-actions-padding-right: 10px;
$msv-cart-price-editor-container-input-height: 48px;
$msv-cart-price-editor-container-expand-modes-button: 12px 40px;
$msv-cart-price-editor-container-expand-modes-button-after-margin: 0 8px;
$msv-cart-price-editor-container-padding-bottom: 20px;

//style presets
:root {
    --msv-checkout-delivery-options-font-color: #{$msv-gray-900};
    --msv-checkout-delivery-options-error-bg: #{$msv-pink};
    --msv-checkout-delivery-options-error-font-color: #{$msv-red};
    --msv-cart-line-wrapper-border-color: #{$msv-gray-50};
}

.ms-checkout-delivery-options {
    &__price {
        @include font-body-regular-m();
        color: var(--msv-checkout-delivery-options-font-color);
    }

    &__description {
        @include font-body-regular-m();
        color: var(--msv-checkout-delivery-options-font-color);
        margin-left: $msv-checkout-description-margin-left;
        width: 100%;
    }

    &__cart-line-grp {
        display: flex;
        flex-wrap: wrap;
        gap: 36px;
        width: 100%;
    }

    &__cart-line-grp__item {
        display: flex;
        flex-wrap: wrap;
        gap: 36px;
        border-radius: 10px;
        border: 0.5px solid #C4C4C4;
        padding: 16px;

        &__header {
            height: 50px;
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
            width: auto;

            &__address-label {
                display: flex;
                color: var(--msv-checkout-delivery-options-font-color);

                @include font-heading-h5-l();
            }

            &__address-value {
                color: var(--msv-checkout-delivery-options-font-color);

                @include font-body-regular-m();
            }
        }

        &__ctn {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;

            &__lbl {
                display: flex;
                flex-wrap: wrap;
                gap: 20px;
                width: 100%;
                border-bottom: 0.5px solid #C4C4C4;
            }

            &__lbl_h {
                color: var(--msv-checkout-delivery-options-font-color);

                @include font-heading-h5-l();
                display: flex;
            }

            &__lbl_t {
                color: var(--msv-checkout-delivery-options-font-color);

                @include font-body-regular-m();
                position: absolute;
                margin-top: 19px;
            }

            &__lbl__date {
                height: 144px;
                padding: 0 0 16 0;
                gap: 4px;
                display: flex;
                flex-wrap: wrap;
            }

            &__lbl__date__checkbox {
                width: 24px;
                height: 24px;
            }

            &__lbl__date__lbl {
                color: var(--msv-checkout-delivery-options-font-color);

                @include font-body-regular-s();
            }

            &__lbl__date__disclaimer {
                color: var(--msv-checkout-delivery-options-font-color);

                @include font-body-regular-xs();
                width: 100%;
            }

            &__lbl__date__calender {
                display: flex;
                flex-wrap: wrap;
                width: 301px;
                height: 72px;
                gap: 4px;
            }

            &__lbl__date__calender-h {
                color: var(--msv-checkout-delivery-options-font-color);

                @include font-body-bold-xs();
            }

            &__lbl__date__calender-c {
                height: 48px;
                padding: 16px 12px 16px 12px;
                gap: 8px;
                border-radius: 3px 0 0 0;
                border: 0.5px solid #C4C4C4;
                width: 100%;
            }

            &__radio-btn-grp {
                display: flex;
                flex-wrap: wrap;
                gap: 4px;
                width: 100%;
                margin-bottom: 20px;

                &-opt {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 12px;
                    width: 100%;
                    height: 28px;
                    align-items: center;

                    &-btn {
                        @include form-input-radio();
                    }

                    &-dsc {
                        color: var(--msv-checkout-delivery-options-font-color);

                        @include font-body-regular-s();
                    }

                    &-pr {
                        color: var(--msv-checkout-delivery-options-font-color);

                        @include font-body-regular-s();
                    }
                }
            }

            &-lines {
                display: flex;
                flex-wrap: wrap;
                gap: 20px;
                width: 100%;
                margin-top: 20px;

                &-item {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 20px;
                    padding: 0, 16px, 0, 16px;
                    width: 100%;
                    height: 213px;

                    .msc-cart-line {
                        display: flex;
                        gap: 10px;
                        width: 100%;

                        .msc-cart-line__product-image {
                            width: $msv-width-full;
                            max-width: $cart-lines-product-image-link-size;
                            margin-right: 20px;

                            @include image($cart-lines-product-image-size);

                            .msc_image {
                                box-sizing: border-box;
                            }

                            .msc-image-container {
                                margin-top: inherit;
                                border: 0.5px solid var(--msv-cart-line-wrapper-border-color);
                            }
                        }

                        .msc-cart-line__catalog-label {
                            @include font-body-regular-xs();
                        }

                        .msc-cart-line__content {
                            width: 100%;

                            @include font-body-regular-xs();

                            .msc-cart-line__product {
                                flex: 1 1 300px;

                                &-title {
                                    @include font-body-bold-m();
                                }

                                &-variants,
                                &-inventory-label,
                                &-quantity-label {
                                    @include font-body-regular-s();
                                    margin-top: 1px;
                                    color: $msv-gray-40;

                                    @media (max-width: $msv-breakpoint-l) {
                                        margin-top: 2px;
                                    }
                                }

                                &-inventory-label {
                                    @include font-body-regular-s();
                                    margin-top: 14px;
                                    color: $msv-gray-40;
                                }

                                &-variant {

                                    &-size,
                                    &-color,
                                    &-style,
                                    &-config {
                                        .name {
                                            padding-left: $cart-cartline-product-variant-name-padding-left;
                                            color: $msv-gray-20;
                                        }
                                    }
                                }

                                &-unit-of-measure {
                                    display: none;

                                    @include font-body-regular-s();
                                    color: $msv-gray-40;
                                    margin-top: 8px;
                                }

                                &-savings {
                                    display: flex;
                                    flex-direction: row;
                                    flex-wrap: wrap;
                                    align-content: flex-start;
                                    justify-content: flex-end;
                                    float: right;
                                    margin-top: -145px;

                                    @media (max-width: $msv-breakpoint-m - 1) {
                                        order: 3;
                                    }

                                    &-label,
                                    &-text {
                                        @include font-body-regular-s();
                                        color: $msv-blue;
                                    }

                                    &-text {
                                        margin-left: 5px;

                                        .msc-price__strikethrough {
                                            display: none;
                                        }
                                    }

                                    &-actual {
                                        display: flex;
                                        flex-direction: column-reverse;
                                        flex: 0 0 100%;
                                        align-items: flex-end;

                                        .msc-price__actual {
                                            @include font-heading-h5-l();

                                            @media (max-width: $msv-breakpoint-m) {
                                                @include font-heading-h5-s();
                                            }

                                            @media (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l) {
                                                @include font-heading-h5-m();
                                            }
                                        }

                                        .msc-price__strikethrough {
                                            @include font-body-regular-s();
                                            color: $msv-gray-40;
                                            text-decoration: line-through;
                                        }
                                    }

                                    .msc-cart-line__product-inventory-label {
                                        @include font-body-regular-s();
                                        color: $msv-red-10;
                                        text-align: right;
                                        margin-top: 5px;
                                    }
                                }

                                &-quantity {
                                    &-label {
                                        margin: $cart-lines-quantity-label-margin;
                                    }
                                }

                                &-unit-price {
                                    .msc-price__actual {
                                        @include font-body-regular-s();
                                    }
                                }

                                &-price {
                                    display: none;
                                }

                                &-discount {
                                    @include font-body-regular-s();
                                    color: $msv-blue;
                                    margin-top: 13px;
                                }
                            }
                        }
                    }

                    .ms-cart-line-grp-deli__radio-btn-grp {
                        width: 650px;
                        position: absolute;
                        gap: 12px;
                        display: flex;
                        flex-wrap: wrap;
                        padding-left: 450px;

                        &-opt {
                            display: flex;
                            flex-wrap: wrap;
                            gap: 8px;
                            width: 100%;
                            align-items: center;

                            &-btn {
                                @include form-input-radio();
                            }

                            &-dsc {
                                @include font-body-regular-s();
                            }

                            &-pr {
                                @include font-body-regular-s();
                            }
                        }
                    }

                    .ms-cart-line-grp-deli-change {
                        width: 819px;
                        position: absolute;
                        gap: 12px;
                        display: flex;
                        flex-wrap: wrap;
                        padding-left: 750px;
                        background-color: transparent;
                        border-color: transparent;
                        color: var(--msv-link-font-color);
                        font-family: Rubik-Regular;
                        font-size: var(--msv-link-font-size-s);
                        font-style: normal;
                        font-weight: 400;
                        line-height: var(--msv-link-line-height-s);
                        margin-left: 20px;
                        margin-top: 100px;
                    }

                    .ms-cart-line-grp-deli-change-btn {
                        background-color: transparent;
                        border-color: transparent;
                        color: var(--msv-link-font-color);
                        cursor: pointer;
                        font-family: Rubik-Regular;
                        font-size: var(--msv-link-font-size-s);
                        font-style: normal;
                        font-weight: 400;
                        line-height: var(--msv-link-line-height-s);
                        text-decoration: underline;
                    }

                    .ms-cart-line-grp-deli-method {
                        width: 819px;
                        position: absolute;
                        gap: 12px;
                        display: flex;
                        flex-wrap: wrap;
                        padding-left: 680px;
                        background-color: transparent;
                        border-color: transparent;
                        color: var(--msv-link-font-color);

                        @include font-body-bold-s();
                        line-height: var(--msv-link-line-height-s);
                        margin-left: 20px;
                        margin-top: 80px;
                    }
                }
            }
        }
    }

    &__option {
        margin-bottom: $msv-checkout-option-margin-bottom;
        display: flex;
        align-items: center;

        @media screen and (max-width: $msv-breakpoint-m) {
            margin-right: 20px;
        }

        &-selected {
            display: flex;
            flex-direction: column;

            .ms-checkout-delivery-options__price {
                text-transform: uppercase;
                margin-top: $msv-checkout-delivery-options-price-margin-top;
            }

            .ms-checkout-delivery-options__description {
                @include font-heading-h5-l();
                margin-left: $msv-checkout-delivery-options-description-margin-left;
            }
        }
    }

    &__input-radio {
        @include form-input-radio();
    }

    &__error-title,
    &__error-message {
        background-color: var(--msv-checkout-delivery-options-error-bg);
    }

    &__error-title {
        @include add-icon($msv-times-circle);
        @include font-body-bold-m();
        padding: $msv-checkout-delivery-options-error-title-padding;
        color: var(--msv-checkout-delivery-options-error-font-color);
        display: flex;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;

        &::before {
            font-size: $msv-font-size-l;
            margin-right: $msv-checkout-delivery-options-error-title-icon-margin-right;
        }
    }

    &__error-message {
        @include font-body-regular-m();
        padding: $msv-checkout-delivery-options-error-message-padding;
        color: var(--msv-checkout-delivery-options-error-font-color);
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    @media screen and (min-width: $msv-breakpoint-l) {
        &__description {
            flex: 0 0 33.33333%;
            max-width: 33.33333%;
        }

        &__price {
            flex: 0 0 16.66667%;
            max-width: 16.66667%;
            margin-left: 0;
        }
    }
}