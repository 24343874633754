header .ms-header .ms-search {
    background-color: $msv-white;
}
header .ms-header .ms-search,
.ms-search {
    @media (min-width: $msv-breakpoint-l) {
        border-radius: 10px;
    }

    .ms-search__form-control {
        border: none;
        border-radius: 10px;
        background-color: $msv-white;
    }

    .ms-search__form-submitSearch,
    .ms-search__form-cancelSearch {
        background-color: $msv-white;
    }
}