

$msv-password-reset-verification-width: 40%;
$msv-password-reset-verification-width-m: 80%;
$msv-password-reset-verification-message-margin-top: 10px;
$msv-password-reset-verification-message-padding: 22px;
$msv-password-reset-verification-account-item-margin-top: 20px;
$msv-password-reset-verification-input-margin-top: 8px;
$msv-password-reset-verification-email-verification-buttons-margin-top: 12px;
$msv-password-reset-verification-button-margin-top: 20px;
$msv-password-reset-verification-form-input-border: $msv-gray-50;
$msv-password-reset-verification-input-height: 52px;
$msv-password-reset-verification-container-margin-m: 60px 20px;
$msv-password-reset-verification-error-icon-font-size: 20px;

//style presets
:root {
    --msv-password-reset-verification-font-color: var(--msv-font-primary-color);

    // heading
    --msv-password-reset-verification-heading-font-color: var(--msv-font-primary-color);

    // input
    --msv-password-reset-verification-form-input-border: #{$msv-password-reset-verification-form-input-border};

    // alert
    --msv-password-reset-verification-success-alert-bg: #{$msv-green-20};
    --msv-password-reset-verification-success-alert-font-color: #{$msv-green-10};
    --msv-password-reset-verification-success-alert-border: transparent;
}

.ms-password-reset-verification {
    @include font-body-regular-m();
    color: var(--msv-password-reset-verification-font-color);
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__container {
        width: $msv-password-reset-verification-width;
        margin: 60px 20px;
    }

    &__heading {
        @include font-heading-h2-l();
        color: var(--msv-password-reset-verification-heading-font-color);
        margin: auto;

        @media (min-width: $msv-breakpoint-m+1) and (max-width: $msv-breakpoint-l) {
            @include font-heading-h2-m();
        }

        @media (max-width: $msv-breakpoint-m) {
            @include font-heading-h2-s();
        }
    }

    &__page-error {
        display: none;

        &[aria-hidden="false"] {
            @include alert();
            line-height: $msv-password-reset-verification-error-icon-font-size;
            // stylelint-disable-next-line declaration-no-important -- to override inline styling to show error after input
            display: flex !important;

            &::before {
                margin-right: 10px;
                font-size: $msv-password-reset-verification-error-icon-font-size;
            }
        }
    }

    &__account-item {
        margin-top: $msv-password-reset-verification-account-item-margin-top;
        display: flex;
        flex-direction: column;

        &[aria-hidden="false"] {
            // stylelint-disable-next-line declaration-no-important -- to override inline styling to show error after input
            display: flex !important;
        }

        &-label {
            @include font-body-bold-s();
        }

        &-input {
            @include font-body-regular-m();
            padding: 16px 48px 16px 16px;
            border: 0.5px solid var(--msv-password-reset-verification-form-input-border);
            margin-top: $msv-password-reset-verification-input-margin-top;
            width: 100%;
            height: $msv-password-reset-verification-input-height;
            background: $msv-white;
            border-radius: 3px;
            color: $msv-gray-40;
        }
    }

    &__email-verification-buttons {
        margin-top: $msv-password-reset-verification-email-verification-buttons-margin-top;
    }

    /* Override inline style inject by AAD so we need important */
    &__page-success[aria-hidden="false"],
    &__page-error[aria-hidden="false"] {
        /* stylelint-disable-next-line declaration-no-important -- Auto-suppressed. Please fix if possible. */
        display: flex !important;
    }

    &__page-success {
        @include font-body-regular-m();
        background-color: var(--msv-password-reset-verification-success-alert-bg);
        color: var(--msv-password-reset-verification-success-alert-font-color);
        display: none;
        margin-top: $msv-password-reset-verification-message-margin-top;
        border-radius: 10px;
        padding: $msv-password-reset-verification-message-padding;
        border-color: var(--msv-password-reset-verification-success-alert-border);

        &[aria-hidden="false"] {
            @include add-icon($msv-Checkbox-Circle-Checked, $icon-weight: $msv-outline-icon-weight);

            &::before {
                color: var(--msv-password-reset-verification-success-alert-font-color);
                margin-right: 13px;
                margin-top: 3px;
                font-size: 20px;
            }
        }
    }

    &__item-error {
        order: 2;

        &[aria-hidden="false"] {
            @include alert();
            margin-top: 13px;
            /* stylelint-disable-next-line declaration-no-important -- Override inline style inject by AAD so we need important. */
            display: flex !important;
            line-height: $msv-password-reset-verification-error-icon-font-size;

            &:empty::before {
                content: "";
            }

            &:not(:empty)::before {
                margin-right: 10px;
                font-size: $msv-password-reset-verification-error-icon-font-size;
            }
        }
    }

    &__email-verification-button,
    &__continue-button {
        @include primary-button-light();
        margin-top: $msv-password-reset-verification-button-margin-top;
        width: 100%;
        text-transform: uppercase;
        height: auto;
    }

    &__cancel-button {
        @include secondary-button-light();
        margin-top: $msv-password-reset-verification-button-margin-top;
        width: 100%;
        text-transform: uppercase;
        height: auto;
    }

    &__verify-email-verify,
    &__verify-email-resend,
    &__continue-button:disabled,
    &__continue-button[aria-disabled="true"] {
        display: none;
    }

    @media screen and (max-width: $msv-breakpoint-m) {
        &__container {
            width: $msv-password-reset-verification-width-m;
        }
    }
}
