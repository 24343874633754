@import "../common/00-settings/responsive-breakpoints.scss";
@import "../common/00-settings/colors.scss";

$msv-breakpoint-ml: 1050px;

[dir="rtl"] {
    .msc-active-image {
        &__details__text {
            @media (max-width: $msv-breakpoint-m) {
                margin-right: 15%;
                text-align: left;
            }

            @media (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l) {
                margin-right: 105px;
            }
        }

        &__details__additional-content__text {
            margin-right: 17px;

            @media (max-width: $msv-breakpoint-m) {
                margin: auto;
            }

            @media (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l) {
                margin: auto;
            }
        }

        &__details__additional-content__paragraph {
            padding-left: 20px;
            padding-right: unset;
        }

        &__details .msc-active-image__details__additional-content__cta__links:nth-child(1),
        &.texttheme__dark .msc-active-image__details .msc-active-image__details__additional-content__cta__links:nth-child(1) {
            border-right: 1px solid $msv-gray-20;
            border-left: none;
        }

        &.texttheme__light .msc-active-image__details .msc-active-image__details__additional-content__cta__links:nth-child(1) {
            border-right: 1px solid $msv-white;
            border-left: none;
        }

        .msc-active-image-product-card-container .msc-active-image-product-card-wrapper .close-button {
            right: unset;
            left: 8px;
        }
    }

    @media (max-width: $msv-breakpoint-ml) {
        .msc-active-image .msc-active-image-product-card-container .msc-active-image-product-card {
            position: relative;
            z-index: 1001;
        }
    }
}
