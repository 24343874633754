$msv-address-form-item-margin-bottom: 10px;
$msv-address-detail-item-margin-right: 5px;
$msv-address-select-item-margin-top: 10px;
$msv-address-select-item-margin-bottom: 10px;
$msv-address-select-item-radio-height: 24px;
$msv-address-select-item-radio-width: 24px;
$msv-address-select-item-address-detail-padding-left: 48px;
$msv-address-button-save-margin-right: 12px;
$msv-account-management-address-margin-bottom: 20px;
$msv-account-management-address-list-primary-heading-margin-top: 24px;
$msv-account-management-address-list-primary-list-address-detail-item-margin-right: 5px;
$msv-primary-list-address-detail-item-newline-margin-right: 0;
$msv-account-management-address-list-primary-list-phone-icon-margin-right: 5px;
$msv-account-management-address-list-primary-list-phone-icon-margin-top: 3px;
$msv-account-management-address-list-button-primary-margin-bottom: 20px;
$msv-account-management-address-textbox-height: 52px;
$msv-address-add-empty-button-add-margin: 22px 0 42px;
$msv-address-add-empty-margin-bottom: 22px;
$msv-address-button-remove-padding-left: 28px;
$msv-address-list-button-primary-padding-left: 5px;
$msv-address-list-button-primary-padding-right: 5px;
$msv-address-list-button-width: 100%;
$msv-address-link-disable-opacity: 0.6;
$msv-address-select-button-add-padding: 0 13px;
$msv-address-select-button-add-content: "+";
$msv-address-select-button-add-padding: 10px 13.5px 10px 0;
$msv-address-select-button-container-padding: 30px;

// info message bar
$msv-address-info-padding: 20px;
$msv-address-info-margin-bottom: 44px;

//style presets
:root {
    --msv-address-border: #{$msv-gray-300};
    --msv-checkout-shipping-address-font-color: var(--msv-font-primary-color);
    --msv-address-link-disabled-color: #{$msv-gray-50};
    --msv-address-select-button-add-color: #{$msv-blue};
    --msv-address-select-button-hover-background-color: #{$msv-white-20};

    //heading
    --msv-address-heading-font-color: var(--msv-font-primary-color);
    --msv-address-primary-heading-font-color: var(--msv-font-primary-color);

    // error
    --msv-address-form-error-border-color: #{$msv-red};
    --msv-address-form-error-font-color: #{$msv-red};

    // info message bar
    --msv-address-info-message-bg: #{$msv-blue-20};
    --msv-address-info-message-border: transparent;

    // table
    --msv-address-select-font-size: var(--msv-body-font-size-m);
}

.msc-address-form {
    &__label {
        @include form-input-label();
    }

    &__item {
        margin-bottom: $msv-address-form-item-margin-bottom;
        display: flex;
        flex-direction: column;
    }

    &__item-isprimary {
        display: flex;

        .msc-address-form__label {
            margin-top: auto;
            margin-bottom: auto;
            margin-right: 10px;

            &:hover {
                cursor: pointer;
            }
        }

        .msc-address-form__input-checkbox {
            width: 20px;

            &:hover {
                cursor: pointer;
            }
        }
    }

    &__item-county {
        display: none;
    }

    &__input-text {
        padding: 6px 8px;
    }

    .MicrosoftMap {
        /* stylelint-disable-next-line declaration-no-important -- Auto-suppressed. Overriding the default CSS added by Bing AutoSuggest. */
        position: static !important;

        width: 100%;

        .as_container_search {
            width: 100%;
        }
    }

    &__input {
        @include form-input-box();
    }

    &__dropdown {
        @include form-dropdown-decoration;
        @include form-input-box();
    }

    &__alert {
        display: flex;
        align-items: flex-end;
        order: 3;
    }

    &__button-save {
        @include primary-button-light();
        margin-right: $msv-address-button-save-margin-right;

        @media screen and (max-width: $msv-breakpoint-m) {
            margin-right: 0;
            margin-bottom: 20px;
            width: 100%;
        }
    }

    &__button-cancel {
        @include secondary-button-light();

        @media screen and (max-width: $msv-breakpoint-m) {
            width: 100%;
        }
    }

    .address-form__item-invalid &__alert,
    .msc-address-form__alert:not(:empty) {
        @include alert;
    }
}

.msc-address-detail {
    &__item {
        margin-right: $msv-address-detail-item-margin-right;
    }

    &__item-newline {
        margin-right: 0;

        &::after {
            content: '\A';
            white-space: pre;
        }
    }

    &__item-address-detail_Phone {
        display: block;
    }

    &__main-item-empty {
        display: none;
    }

    &__item-phone {
        @include add-icon($msv-Phone);

        &-label {
            @include visually-hidden();
        }

        &::before {
            margin-right: $msv-account-management-address-list-primary-list-phone-icon-margin-right;
            margin-top: $msv-account-management-address-list-primary-list-phone-icon-margin-top;
            float: left;
        }

        &.msc-address-detail__item-empty {
            display: none;
        }
    }
}

.msc-address-select {
    &__wrapper {
        max-height: 415px;
        overflow-y: auto;
        margin: 20px 0;
    }

    &__input-radio {
        height: $msv-address-select-item-radio-height;
        width: $msv-address-select-item-radio-width;
        position: absolute;
        top: 0;
    }

    &__item {
        display: flex;
        align-items: center;
        margin-top: $msv-address-select-item-margin-top;
        margin-bottom: $msv-address-select-item-margin-bottom;
        position: relative;

        .msc-address-detail {
            padding-left: $msv-address-select-item-address-detail-padding-left;
        }
    }

    &__input {
        @include form-input-checkbox();
    }

    &__button-add {
        @include primary-button-light();
    }

    &__button-ship-multiple-addresses {
        background: transparent;
        border: none;
        color: var(--msv-link-font-color);
        font-family: $bc-primary-font-family;
        font-size: var(--msv-link-font-size-xs);
        font-style: normal;
        font-weight: 400;
        line-height: var(--msv-link-line-height-xs);
        padding: 0;
        text-decoration: underline;
        margin-bottom: 30px;

        @media screen and (max-width: $msv-breakpoint-m) {
            align-self: start;
            margin-bottom: 10px;
        }
    }

    &__button-ship-single-address {
        background: transparent;
        border: none;
        color: var(--msv-link-font-color);
        font-family: $bc-primary-font-family;
        font-size: var(--msv-link-font-size-xs);
        font-style: normal;
        font-weight: 400;
        line-height: var(--msv-link-line-height-xs);
        margin-bottom: 10px;
        padding: 0;
        text-decoration: underline;

        @media screen and (max-width: $msv-breakpoint-m) {
            display: flex;
            margin-bottom: 20px;
        }
    }

    &__ship-single-button-container {
        width: 100%;
        text-align: right;
    }

    &__clear-selection-button {
        background: #FFFFFF;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: $bc-primary-font-family;
        font-size: x-small;
        font-style: normal;
        font-weight: 400;
        height: 30px;
        width: 115px;
        line-height: 20px;
        outline: none;
        padding: 16px 5px;
        border-right: 1px solid var(--msv-secondary-btn-light-pressed-bg-color);
        margin-right: 3px;

        &::before {
            @include msv-icon();
            content: $msv-trash;
            font-size: 18px;
            padding-right: 5px;

            @media screen and (max-width: $msv-breakpoint-m) {
                color: $msv-blue;
            }
        }

        @media screen and (max-width: $msv-breakpoint-m) {
            border-right: none;
            font-size: 12px;
            width: 130px;
        }
    }

    &__address-dropdown {
        background: #FFFFFF;
        border: 1px solid var(--msv-secondary-btn-light-pressed-bg-color);
        border-radius: 4px;
        box-sizing: border-box;
        font-family: $bc-primary-font-family;
        font-size: small;
        font-style: normal;
        font-weight: 400;
        height: 30px;
        line-height: 20px;
        outline: none;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @media screen and (max-width: $msv-breakpoint-m) {
            height: 50px;
            font-size: 14px;
        }
    }

    &__address-dropdown option {
        font-family: $bc-primary-font-family;
        font-size: small;
        font-style: normal;
        font-weight: 400;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__current-address-dropdown {
        background: #FFFFFF;
        border: 1px solid var(--msv-secondary-btn-light-pressed-bg-color);
        border-radius: 3px;
        box-sizing: border-box;
        font-family: $bc-primary-font-family;
        font-size: x-small;
        font-style: normal;
        font-weight: 400;
        height: 30px;
        width: 170px;
        line-height: 30px;
        position: relative;
        padding-right: 20px;

        @media screen and (max-width: $msv-breakpoint-m) {
            width: 285px;
            font-size: small;
            height: 50px;
        }
    }

    &__current-address-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        max-width: calc(100% - 10px);
    }

    &__address-button-container {
        display: flex;
        justify-content: space-between;

        @media screen and (max-width: $msv-breakpoint-m) {
            display: flex;
            flex-direction: column-reverse;
        }
    }

    &__heading-button-container {
        display: flex;
        margin-bottom: 5px;

        @media screen and (max-width: $msv-breakpoint-m) {
            flex-direction: column-reverse;
        }
    }

    &__dropdown-button-wrapper {
        width: 100%;
        margin-left: 10px;

        @media screen and (max-width: $msv-breakpoint-m) {
            margin-left: 0;
        }
    }

    &__product-price {
        display: flex;
        margin-top: 10px;
        font-family: $bc-primary-font-family;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
    }

    &__table {
        width: 100%;
        border-collapse: collapse;

        th {
            text-align: left;
        }

        tr {
            height: 82px;
        }

        .ms-table__heading-row {
            @include font-body-bold-xs();
            height: 50px;
            border-top: 1px solid var(--msv-secondary-btn-light-pressed-bg-color);
            border-bottom: 1px solid var(--msv-secondary-btn-light-pressed-bg-color);
        }

        .ms-address-select-table-line {
            height: 82px;

            color: var(--msv-body-font-color);
            font-family: $bc-primary-font-family;
            font-size: var(--msv-body-font-size-xs);
            font-style: normal;
            font-weight: 400;
            line-height: var(--msv-body-line-height-s);

            border-bottom: 1px solid var(--msv-secondary-btn-light-pressed-bg-color);

            &__heading {
                height: 43px;

                color: var(--msv-body-font-color);
                font-family: $bc-primary-font-family;
                font-size: var(--msv-body-font-size-xs);
                font-style: normal;
                font-weight: 700;
                line-height: var(--msv-body-line-height-xs);

                th {
                    @include font-content(var(--msv-font-weight-normal),
                        var(--msv-address-select-font-size), $msv-line-height-m);
                    text-align: start;
                }
            }

            @media screen and (max-width: $msv-breakpoint-m) {
                height: 150px;
            }
        }
    }

    .checkbox-container {
        display: block;
        position: relative;
        margin-left: 18px;
        padding-left: 35px;
        cursor: pointer;
        font-size: $msv-font-size-l;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        height: 15px;

        &:focus {
            border: 1px solid $msv-blue;
            border-radius: 100px;
        }

        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 15px;
            width: 15px;
            top: -2px;
            left: -3px;
        }

        input:focus {
            border: 1px solid var(--msv-accent-brand-color);
            opacity: 1;

            @media screen and (max-width: $msv-breakpoint-m) {
                border-color: $msv-blue;
            }
        }

        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 15px;
            width: 15px;
            background-color: transparent;
            border: 1.5px solid var(--msv-accent-brand-color);
            border-radius: 1rem;
        }

        input:checked~.checkmark {
            background-color: var(--msv-accent-brand-color);

            @media screen and (max-width: $msv-breakpoint-m) {
                background-color: $msv-blue;
                border-color: $msv-blue;
            }
        }

        .checkmark::after {
            content: "";
            position: absolute;
            display: none;
            left: 4px;
            width: 5px;
            height: 10px;
            border: solid $msv-white;
            background-color: var(--msv-accent-brand-color);
            border-width: 0 1.75px 1.75px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);

            @media screen and (max-width: $msv-breakpoint-m) {
                background-color: $msv-blue;
            }
        }

        input:checked~.checkmark::after {
            display: block;
        }

        @media screen and (max-width: $msv-breakpoint-m) {
            font-family: $bc-primary-font-family;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            padding-left: 15px;
        }
    }

    &__button-add-new {
        @include font-body-regular-s();
        line-height: 100%;
        background-color: transparent;
        border: 0;
        display: inline-flex;
        align-items: center;
        cursor: pointer;
        padding: $msv-address-select-button-add-padding;

        &::before {
            content: $msv-address-select-button-add-content;
            padding: $msv-address-select-button-add-padding;
            color: var(--msv-address-select-button-add-color);
            font-size: $msv-font-size-ml;
        }

        &:hover {
            background: var(--msv-address-select-button-hover-background-color);
        }

        @media screen and (max-width: $msv-breakpoint-m) {
            margin-bottom: 20px;
        }
    }

    &__button-container {
        margin-bottom: $msv-address-select-button-container-padding;
    }

    &__save-continue-button {
        @include primary-button-light();
        margin-left: 10px;

        @media screen and (max-width: $msv-breakpoint-m) {
            width: 100%;
            margin-left: 0;
            margin-top: 10px;
        }
    }

    &__cancel-button {
        @include secondary-button-light();

        @media screen and (max-width: $msv-breakpoint-m) {
            width: 100%;
        }
    }

    &__button-footer-wrapper {
        display: flex;
        margin-top: 40px;

        @media screen and (max-width: $msv-breakpoint-m) {
            display: block;
            margin-top: 10px;
        }
    }
}

// mixin

@mixin address-button {
    cursor: pointer;
}

.ms-account-management-address {
    margin-bottom: 80px;

    &__info {
        @include add-icon($msv-information);
        @include font-body-regular-m();
        @include vfi();
        background-color: var(--msv-address-info-message-bg);
        border: 1px solid var(--msv-address-info-message-border);
        padding: $msv-address-info-padding;
        margin-bottom: $msv-address-info-margin-bottom;
        border-radius: 10px;

        &::before {
            font-size: $msv-font-size-ml;
            padding-right: 10px;
            top: 2px;
            position: relative;
            vertical-align: top;
            color: $msv-blue;
        }
    }

    .msc-address-list__button-disabled {
        color: var(--msv-address-link-disabled-color);
        pointer-events: none;
        opacity: $msv-address-link-disable-opacity;
    }

    .ms-address-list {
        margin-bottom: $msv-account-management-address-margin-bottom;

        @media screen and (min-width: $msv-breakpoint-m) {
            width: 50%;
        }
    }

    .msc-address-list__heading {
        @include font-heading-h3-m();
        color: var(--msv-address-heading-font-color);
        padding-bottom: 24px;
        border-bottom: 0.5px solid $msv-gray-50;
    }

    .msc-address-list__add-empty {
        margin-top: 34px;
        margin-bottom: $msv-address-add-empty-margin-bottom;
        line-height: $msv-mobile-title-line-height-xs;
    }

    .msc-address-list__button-add {
        @include primary-button-light();
        margin: 35px 0;
    }

    .msc-address-list__add-empty~.msc-address-list__button-add {
        margin: $msv-address-add-empty-button-add-margin;
    }

    .msc-address-list__primary {
        &.invoice {
            border-top: 1px solid $msv-gray-50;
            padding-top: 45px;
        }

        .msc-address-list__primary-heading {
            @include font-heading-h5-l();

            color: var(--msv-address-primary-heading-font-color);
        }

        .msc-address-list__primary-list {
            border-bottom: 0.5px solid $msv-gray-50;
            padding-bottom: 35px;

            &::nth-child(2) {
                padding-top: 0;
            }

            &::last-child {
                border-bottom: none;
                padding-bottom: 0;
            }

            .msc-address-detail {
                @include font-body-regular-m();
                padding-bottom: 15px;
                padding-top: $msv-account-management-address-list-primary-heading-margin-top;

                .msc-address-detail__item {
                    margin-right: $msv-account-management-address-list-primary-list-address-detail-item-margin-right;
                }

                .msc-address-detail__item-newline {
                    margin-right: $msv-primary-list-address-detail-item-newline-margin-right;

                    &::after {
                        content: '\A';
                        white-space: pre;
                    }
                }

                &__item-address-detail_Phone {
                    margin-top: 15px;
                }

                &__item-phone {
                    &::before {
                        margin-right: 15px;
                    }
                }
            }

            .msc-address-list__button-primary {
                @include secondary-button-light();
                display: block;
                margin-bottom: $msv-account-management-address-list-button-primary-margin-bottom;
                margin-top: 15px;
                opacity: unset;

                @media screen and (max-width: $msv-breakpoint-m) {
                    padding-left: $msv-address-list-button-primary-padding-left;
                    padding-right: $msv-address-list-button-primary-padding-right;
                    width: $msv-address-list-button-width;
                }
            }

            .msc-address-list__button-edit {
                border: none;
                background: none;

                @include link-regular-m();
                text-decoration: none;
                border-radius: 0;
                padding-right: 30px;
                padding-left: 0;
                cursor: pointer;
            }

            .msc-address-list__button-remove {
                border: none;
                background: none;

                @include link-regular-m();
                text-decoration: none;
                border-radius: 0;
                border-left: 0.5px solid $msv-gray-50;
                margin-top: 10px;
                padding-left: $msv-address-button-remove-padding-left;
                cursor: pointer;
            }
        }

        .msc-address-list__primary-list:last-child {
            border-bottom: none;
        }
    }

    .msc-address-form {
        &__item {
            margin-bottom: 40px;
        }
    }

    .remove-address-screen-reader {
        height: 1px;
        overflow: hidden;
        position: absolute;
    }

    .msc-address-form__item-street {
        .msc-address-form__input-text {
            padding: 0 0 0 12px;
        }
    }
}