.msc-modal {
    .msc-modal__dialog.msc-modal__csv {
        .msc-modal__title {
            display: flex;
            gap: 20px;
            align-items: center;
        }
        .download-csv-btn {
            a {
                @include add-icon($msv-file-excel);
                @include msv-roboto-regular($font-size: 16px);
                display: flex;
                align-items: center;
                gap: 5px;
                cursor: pointer;
                text-decoration: none;
                &:before {
                    font-size: 20px;
                    text-decoration: none;
                    color: $msv-green-excel;
                }
                &:hover,
                &:focus {
                    span {
                        text-decoration: underline;
                    }
                    &:before {
                        text-decoration: none;
                    }
                }
            }
        }
        .msc-modal__body {
            min-height: 100px;
            padding-top: 20px;
            overflow: hidden;

            .upload-csv-btn {
                display: flex;
                align-items: center;
                gap: 15px;
                input {
                    display: none;
                }
                .btn-primary {
                    @include primary-button();
                    cursor: pointer;
                }
                &--error {
                    color: $msv-red-10;
                }
            }
            .csv-text-notfound,
            .csv-text-valid > h4 {
                display: block;
                padding: 0 37px;
            }
            .csv-text-valid,
            .csv-text-invalid {
                margin-top: 15px;
            }
            .csv-text-notfound {
                margin: 20px 0;
                color: $msv-gray-500;
            }
            .msc-add-line-to-template__product__superseded {
                @include msv-roboto-regular($font-size: 14px);
                color: $msv-blue-30;
            }
            .msc-add-line-to-template__product__repairable {
                color: $msv-blue-30;
                padding-top: 0;
                padding-bottom: 0;
            }
            .msc-add-line-to-template__product__quantity {
                font-weight: 600;
                span {
                    font-weight: 400;
                }
            }
            .msc-add-line-to-template__product__quantity,
            .msc-add-line-to-template__product__unit-price {
                margin-bottom: 4px;
            }
            .msc-add-line-to-template__product-config {
                border-top: 1px solid $msv-gray-210;
                &-wrapper {
                    overflow-y: auto;
                    max-height: 380px;
                }
            }
            .msc-add-line-to-template__product__positioning-container-1 {
                min-height: auto;
                flex: auto;
                justify-content: center;
                gap: 15px;
                flex-direction: column;
                @media (min-width: 450px) {
                    flex-direction: row;
                    align-items: center;
                }
                .msc-add-line-to-template__product__info {
                    min-width: 180px;
                    padding-left: 37px;
                }
            }
        }
        .msc-modal__footer {
            gap: 15px;
        }
    }
}
