.bt-0 {
    border-top: 0 !important;
}

.repairable-item-text,
.superseded-item-text {
    @include msv-roboto-regular($font-size: 14px);
    color: $msv-primary;
    padding-top: 5px;
    padding-bottom: 10px;
    padding-right: 10px;

    &.fs-12 {
        font-size: 12px;
    }

    &.pt-0 {
        padding-top: 0;
    }
}

.dropship-item-text,
.special-item-text {
    @include msv-roboto-regular($font-size: 14px);
    color: $msv-blue-flat;
    padding-top: 5px;
    padding-bottom: 10px;
    padding-right: 10px;
}

.stock-item-text {
    @include msv-roboto-bold($font-size: 14px);
    padding: 5px;
    text-align: left;
}

.oos-item-text {
    color: $msv-red-flat;
}

.limited-item-text {
    color: $msv-yellow-flat;
}

.available-item-text {
    color: $msv-green-flat;
}

// CSS CUSTOM GLOBAL STYLES

//COLORS
.dkblue {
    color: $bc-color-dkblue;
}

.mdblue {
    color: $bc-color-mdblue;
}

.ltblue {
    color: $bc-color-ltblue;
}

.white {
    color: $msv-white;
}

.whitebkg {
    background-color: $msv-white;
}

.lightgray {
    background-color: $bc-color-ltgrey;
}

.darkpeach {
    background-color: $bc-color-dkpeach;
}

.mediumpeach {
    background-color: $bc-color-mdpeach;
}

.superdarkblue {
    background-color: $bc-color-spdkblue;
}

.darkblue {
    background-color: $bc-color-dkblue;
}

.mediumblue {
    background-color: $bc-color-mdblue;
}

.lightblue {
    background-color: $bc-color-ltblue;
}

// Helpers
.blanksq {
    height: 100%;
}

.shadow {
    text-shadow: 2px 2px 5px $msv-black;
}

.pb3 {
    padding-bottom: 3%;
}

.pb1 {
    padding-bottom: 1%;
}

.pl2 {
    padding-left: 2%;
}

.pl3 {
    padding-left: 3%;
}

.pl4 {
    padding-left: 4%;
}

.pt3 {
    padding-top: 3%;
}

.pt1 {
    padding-top: 1%;
}

.center {
    text-align: center;
    margin: auto;
}

.nowrap {
    white-space: nowrap;
}

.flt-left {
    float: left;
}

.wave {
    @include wave();
}

.rcb {
    font-weight: 700;
}

.link-wt a {
    color: $msv-white;
    text-decoration: none;

    &:hover,
    &:focus {
        color: $bc-color-ltblue;
        text-decoration: none;
    }
}

.link a {

    &:hover,
    &:focus {
        color: $bc-color-ltblue;
        text-decoration: none;
    }
}