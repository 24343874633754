.msc-price {
    .msc-price__salesagreementprice {
        button.msc-price__salesagreementprompt_expanded,
        button.msc-price__salesagreementprompt_collapsed {
            background: transparent;
            border: none;
            color: $msv-blue;
            margin-top: 34px;
        }

        .msc-price__salesagreementprompt_expanded::after,
        .msc-price__salesagreementprompt_collapsed::after {
            color: $msv-gray-20;
            line-height: 16px;
            width: 16px;
            margin-top: 2px;
            position: absolute;
        }

        .msc-price__salesagreementprompt_collapsed {
            @include add-icon($msv-ChevronDown, after);
        }

        .msc-price__salesagreementprompt_expanded {
            @include add-icon($msv-ChevronUp, after);
        }

        .msc-price__salesagreementdetail {
            margin-top: 6px;
        }
    }
}
