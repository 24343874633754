.ms-checkout__guided-card.billing-address-section {

    &.no-invoice {
        display: none;
    }

    .ms-checkout__guided-card-header .ms-checkout__guided-card-btn-edit,
    .ms-checkout__guided-card-footer {
        display: none !important;
    }

    .msc-address-detail {
        &__buttons {
            margin-top: 40px;
            margin-bottom: 8px;
            display: flex;
        }
    }

    .billing-address-change-btn {
        position: absolute;
        top: 0;
        right: 0;
        margin-top: 34px;
    }
}